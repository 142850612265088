<script>
  import PostInterpretationPrototype from '@/prototypes/PostInterpretationPrototype';

  export default {
    asyncData({store, route}) {
      let promisesArr = '';

      if (!route.query.copy) {
        store.dispatch('PostingNotifierStore/preselectInterpretationStaticData');
      }
      if (store.getters['PostingNotifierStore/mainDataShouldBeFetched']) {
        promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/fetchMainData')];
      }
      // Filling the selectors for pricing templates
      if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.demanderTemplates).length === 0) {
        promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'demander')];
      }
      if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.supplierTemplates).length === 0) {
        promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'supplier')];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    extends: PostInterpretationPrototype,
    data() {
      return {
        isSeriesJobForm: true
      };
    },
    computed: {
      mainLines() {
        return [{
          components: [{
            name: 'language-section',
            params: {
              langToText: this.$gettext('Language'),
              disabledFields: this.disabledImportantFields,
              useSelectLanguagePopup: true
            }
          }]
        }, {
          components: [{
            name: 'date-time-section',
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'multiple-date-time-inner-section',
            condition: this.showMultipleDatetimeInnerSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'subject-section',
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'warning-section',
            condition: true
          }]
        }, {
          components: [{
            name: 'assignment-type-section',
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            }
          }]
        }];
      },
      additionalLines() {
        return [{
          components: [{
            name: 'alternative-language-section',
            condition: this.showAlternativeLanguageSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'several-suppliers-section',
            condition: this.showSeveralSuppliersSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'latest-suppliers-list-section',
            condition: this.showLatestSuppliersListSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'gender-section',
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'qualification-section',
            condition: this.showQualificationSection,
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'blocked-suppliers-section',
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'blocked-cities-section',
            condition: this.showBlockedCitiesSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'extra-info-inner-section',
            params: {
              showExtraInfoNote: true,
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'attachments-inner-section',
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'participants-section',
            condition: this.showParticipantsSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }];
      },
      footerTextParams() {
        return {
          dateTimes: [
            ...[{
              startTime: this.fullStartTime,
              finishTime: this.fullFinishTime
            }],
            ...this.dateTimes
          ],
          languageId: this.languageTo
        };
      }
    },
    methods: {
      getCopyJob() {
        const replacement = this.$route.query.replacement && this.$store.state.TTAuthStore.godmode;
        let promisesArr = '';
        promisesArr = [...promisesArr, this.$store.dispatch('PostInterpretationStore/getCopyJob', {copyJobId: this.$route.query.copy, replacement})];
        Promise.all(promisesArr).then(() => {
          this.postAssignmentProgress = false;
        }).catch(() => {
          this.postAssignmentProgress = false;
        });
      }
    },
    mounted() {
      if (this.$route.query.copy) {
        this.getCopyJob();
      }
    }
  };
</script>
